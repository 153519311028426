import React from "react";
import { RichText } from '@sitecore-jss/sitecore-jss-react';


const calculateWidthPercentage = (cell, type) => {
  if (type === 'bar') {
    // bar
    return 10 / 11 * (Number(cell.barMax.value) - Number(cell.barMin.value)) + '%';
  } else if (type === 'header') {
    // heading
    if (Number(cell.barMin.value) !== 0) {
      return ((Number(cell.barMax.value) - Number(cell.barMin.value) + 1) / 110 * 100) + '%';
    } else {
      return ((Number(cell.barMax.value) - Number(cell.barMin.value)) / 110 * 100) + '%';
    }
  } else {
    return '';
  }
}

const calculateMarginPercentage = (cell) => {
  if (cell.barCell.value) {
    return 10 / 11 * Number(cell.barMin.value) + '%';
  } else {
    return '';
  }
}
 
const SocBarchart = ({ fields }) => {
  let mIndex = 0;

  return (
    <div
      className={`soc-barchart mt-7 row ${fields["css classes"]?.value}`}
    >
      <div className="col-12 col-md-10 mx-auto">

        {
          fields.data.item.rows.map((row, index) => {
            if (row?.showOnMobile?.value) {
              mIndex += 1;
            }

            return (
              <div key={index} className={`
                table-row 
                ${row.showOnMobile.value ? 'show-on-mobile animation-' + mIndex : ''}  
                ${row.headerRow.value ? 'header-row': ''}  
                ${row.metricRow.value ? 'metric-row': ''}  
                `}>
  
                {/* header cell */}
                {
                  row.cells.filter((cel) => (
                    cel.headerCell.value
                  )).map((item, id) => (
                    <div key={'cell-h-' + index + '-' + id} 
                      className={`header-cell`}
                    >
                      <RichText className="text-content" field={item.text}/>
                    </div>
                  ))
                }
  
                {/* content cell */}
                <div className="content-cells">              
                  {row.cells.map((cell, idx) => (
                    !cell.headerCell.value && <div key={'cell-' + index + '-' + idx} 
                    className={
                      `cell 
                      ${cell.barCell.value ? 'bar-cell animation-' + index : ''} 
                      `}
                    style={{
                      marginLeft: calculateMarginPercentage(cell),
                      width: calculateWidthPercentage(cell, (row.headerRow.value ? 'header' : cell.barCell.value ? 'bar' : ''))
                    }}
                    >
                        <RichText className="text-content" field={cell.text}/>
                    </div>
                  ))}
                </div>
  
              </div>
            )

          })
        }

      </div>
    </div>
  );
};

export default SocBarchart;
